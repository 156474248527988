<template>
  <PageCard pageName="FIN CRM" :iconClass="['fa', 'icon-layers', 'fa-lg']">
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">CRM Data Import License</div>
            <b-card-body class="p-0">
              <div class="font-weight-bold text-center">
                Easily Energize your CRM system with Actionable Account Data.
              </div>
              <div class="my-2">
                CRM systems are only as good as the data housed within them. If
                your account data consists of basic firmographics (i.e. asset
                size) and some contact data, then something’s missing.
              </div>
              <div class="my-2">
                What’s missing is insight – insight about each financial
                institution’s technology stack and performance challenges. After
                all, if we’re going to sell solutions, shouldn’t we know their
                problems?
              </div>
              <div class="my-2">
                Give your teams a competitive operating advantage by enriching
                your CRM system with financial institutions' technographic
                (offering), performographic (profit, growth & risk) and
                firmographic (basic segmentation) account data.
              </div>
              <div class="my-2">
                Contact your administrator or FI Navigator to learn more about
                adding a CRM Data License.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Single-Click Access from your CRM</div>
            <b-card-body class="p-0">
              <div class="font-weight-bold text-center">
                Detailed Analysis on Every FI is Just One Click Away.
              </div>
              <div class="my-2">
                By embedding FI Navigator’s single-click access into your CRM
                Account record, users will always have the most advanced FI
                analytics at their fingertips.
              </div>
              <div class="my-2">
                The integrated button or link is simple to deploy. Clicking the
                link will automatically login the user and launch FIN Profile or
                FIN Reporter for the financial institution.
              </div>
              <div class="my-2">
                No additional login is required!
              </div>
              <div class="my-2">
                Make meeting prep more efficient and valuable with fast access
                to performance metrics, technographics, segmentation, peer
                analytics, and more.
              </div>
              <div class="my-2">
                Contact your administrator or FI Navigator to learn more about
                adding Single-Click Access to your CRM.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">CRM Supporting Data</div>
            <b-card-body class="p-0">
              <!--<b-row class="pb-4">
                <b-col cols="9" class="px-1">
                  <div>
                    <h5>Holding Company Structure</h5>
                    <div class="small_font">
                      Download list of all Banks/CUs with BHC structure.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="downloadFile('holdingCompanyStructure')"
                    class="download_btn"
                    >Download</b-button
                  >
                </b-col>
              </b-row>-->
              <b-row class="pb-4">
                <b-col cols="9" class="px-1">
                  <div>
                    <h5>Merger History</h5>
                    <div class="small_font">
                      Download list of all acquisitions by Bank/CU/BHC.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="downloadFile('mergerHistory')"
                    class="download_btn"
                    >Download</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="9" class="px-1">
                  <div>
                    <h5>All Branches w/SOD</h5>
                    <div class="small_font">
                      Download list of all branches with SOD.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="downloadFile('allBranches')"
                    class="download_btn"
                    >Download</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="9" class="px-1">
                  <div>
                    <h5>Denovo Report</h5>
                    <div class="small_font">
                      Download the Denovo Report.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="downloadFile('denovoReport')"
                    class="download_btn"
                    >Download</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="9" class="px-1">
                  <div>
                    <h5>Inactive FIs</h5>
                    <div class="small_font">
                      Download list of all Inactive Financial Institutions.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="downloadFile('inactiveFIs')"
                    class="download_btn"
                    >Download</b-button
                  >
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import axios from 'axios'

export default {
  name: 'AboutCRMdata',
  components: {
    PageCard
  },
  data() {
    return {
      downloadApiUrl: {
        holdingCompanyStructure:
          '/api/crm_records/download_holding_company_structure',
        mergerHistory: '/api/crm_records/download_merger_history',
        allBranches: '/api/crm_records/download_all_branches_with_deposits',
        denovoReport: '/api/crm_records/download_denovo_report',
        inactiveFIs: '/api/crm_records/download_inactive_fis'
      }
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN CRM'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  methods: {
    downloadFile(fileType) {
      return axios
        .get(this.downloadApiUrl[fileType], {
          handleErrors: true
        })
        .then(response => {
          this.downloadResult(
            response.data.file_url.replaceAll('\u0026', '&'),
            fileType + '.csv'
          )
        })
        .then(() => {})
    },
    downloadResult(fileUrl, fileName) {
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = fileUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.download_btn {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0.5rem;
  background-color: #fff;
}
.small_font {
  font-size: 13px;
}
.download_col {
  display: flex;
  align-items: center;
}
</style>
